module.exports = {
    "GLOBAL": {
        "DISKS": {
            "s3": {
                "driver": "s3",
                "region": "us-west-1",
                "bucket": "dubb-app",
                "name": "Global"
            },
            "cf-apac": {
                "driver": "s3",
                "region": "auto",
                "bucket": "dubb-apac",
                "name": "Cloudflare Asia-Pacific"
            },
            "cf-eeur": {
                "driver": "s3",
                "region": "auto",
                "bucket": "dubb-eeur",
                "name": "Cloudflare Eastern Europe"
            },
            "cf-enam": {
                "driver": "s3",
                "region": "auto",
                "bucket": "dubb-enam",
                "name": "Cloudflare Eastern North America"
            },
            "cf-weur": {
                "driver": "s3",
                "region": "auto",
                "bucket": "dubb-weur",
                "name": "Cloudflare Western Europe"
            },
            "cf-wnam": {
                "driver": "s3",
                "region": "auto",
                "bucket": "dubb-wnam",
                "name": "Western North America"
            },
            "do": {
                "driver": "do",
                "region": "sfo2",
                "bucket": "dubb",
                "name": "San Francisco"
            },
            "do-sfo3": {
                "driver": "do",
                "region": "sfo3",
                "bucket": "dubb-sfo3",
                "name": "San Francisco"
            },
            "do-nyc3": {
                "driver": "do",
                "region": "nyc3",
                "bucket": "dubb-nyc3",
                "name": "New York"
            },
            "do-sgp1": {
                "driver": "do",
                "region": "sgp1",
                "bucket": "dubb-sgp1",
                "name": "Singapore"
            },
            "do-fra1": {
                "driver": "do",
                "region": "fra1",
                "bucket": "dubb-fra1",
                "name": "Frankfurt"
            },
            "do-ams3": {
                "driver": "do",
                "region": "ams3",
                "bucket": "dubb-ams3",
                "name": "Amsterdam"
            }
        }
    },
    "App_Models_Announcement": {
        "typesMap": {
            "content": "Content",
            "redirect": "Redirect"
        }
    },
    "App_Models_AvatarVideo": {
        "preprocessesMap": {
            "crop": "Crop",
            "extcrop": "Ext Crop",
            "full": "Full",
            "extfull": "Ext Full",
            "resize": "Resize"
        }
    },
    "App_Models_BackgroundJob": {
        "statusMap": {
            "pending": "Pending",
            "processing": "Processing",
            "finished": "Finished",
            "failed": "Failed"
        },
        "typeMap": {
            "import_contacts": "Import Contacts",
            "clean_contacts_phone": "Clean Contacts Phone Number"
        }
    },
    "App_Models_BroadcastSetting": {
        "typesMap": {
            "email": "Email",
            "sms": "SMS"
        },
        "emailProvidersMap": {
            "gmail_api": {
                "name": "Gmail API",
                "class": "App\\Extensions\\BroadcastProviders\\Email\\GmailApiEmailProvider",
                "auth": {
                    "connect_route_name": "web.broadcast-provider.gmail.redirect",
                    "btn_class": "google-btn",
                    "btn_icon": "fab fa-google text-white"
                }
            },
            "gmail_smtp": {
                "name": "Gmail SMTP",
                "class": "App\\Extensions\\BroadcastProviders\\Email\\GmailSmtpProvider",
                "help": "Please <a href=\"https:\/\/support.dubb.com\/en\/articles\/5764675-how-to-configure-google-gmail-to-campaign-sender\" target=\"_blank\">turn on access for less secure apps<\/a>.",
                "auth": null
            },
            "mailgun": {
                "name": "Mailgun",
                "class": "App\\Extensions\\BroadcastProviders\\Email\\MailgunProvider",
                "auth": null
            },
            "mandrill_smtp": {
                "name": "Mandrill SMTP",
                "class": "App\\Extensions\\BroadcastProviders\\Email\\MandrillSmtpProvider",
                "auth": null
            },
            "outlook_api": {
                "name": "Outlook",
                "class": "App\\Extensions\\BroadcastProviders\\Email\\OutlookApiEmailProvider",
                "auth": {
                    "connect_route_name": "web.broadcast-provider.outlook.redirect",
                    "btn_class": "outlook-btn",
                    "btn_icon": "fab fa-microsoft text-white"
                }
            },
            "send_grid_smtp": {
                "name": "SendGrid SMTP",
                "class": "App\\Extensions\\BroadcastProviders\\Email\\SendGridSmtpProvider",
                "auth": null
            },
            "ses": {
                "name": "Amazon AWS SES",
                "class": "App\\Extensions\\BroadcastProviders\\Email\\SesEmailProvider",
                "help": "<a href=\"https:\/\/support.dubb.com\/en\/articles\/5764929-how-to-configure-amazon-aws-simple-email-service-ses-smtp-settings-to-dubb\" target=\"_blank\">How to Configure AWS SES<\/a>",
                "auth": null
            },
            "smtp": {
                "name": "SMTP",
                "class": "App\\Extensions\\BroadcastProviders\\Email\\SmtpEmailProvider",
                "auth": null
            }
        },
        "smsProvidersMap": {
            "8x8": {
                "name": "8x8",
                "class": "App\\Extensions\\BroadcastProviders\\Sms\\EightXEightProvider",
                "auth": null
            },
            "click_send": {
                "name": "ClickSend",
                "class": "App\\Extensions\\BroadcastProviders\\Sms\\ClickSendProvider",
                "auth": null
            },
            "dialpad": {
                "name": "Dialpad",
                "class": "App\\Extensions\\BroadcastProviders\\Sms\\DialpadProvider",
                "auth": null
            },
            "ez_texting": {
                "name": "EZ Texting",
                "class": "App\\Extensions\\BroadcastProviders\\Sms\\EzTextingProvider",
                "auth": null
            },
            "just_call": {
                "name": "JustCall",
                "class": "App\\Extensions\\BroadcastProviders\\Sms\\JustCallProvider",
                "auth": null
            },
            "ring_central": {
                "name": "RingCentral",
                "class": "App\\Extensions\\BroadcastProviders\\Sms\\RingCentralProvider",
                "auth": null
            },
            "sakari": {
                "name": "Sakari",
                "class": "App\\Extensions\\BroadcastProviders\\Sms\\SakariProvider",
                "auth": null
            },
            "sales_message": {
                "name": "SalesMessage",
                "class": "App\\Extensions\\BroadcastProviders\\Sms\\SalesMessageProvider",
                "auth": {
                    "connect_route_name": "web.broadcast-provider.sales-message.redirect",
                    "btn_class": "btn-primary"
                }
            },
            "signal_wire": {
                "name": "SignalWire",
                "class": "App\\Extensions\\BroadcastProviders\\Sms\\SignalWireProvider",
                "auth": null
            },
            "simple_texting": {
                "name": "SimpleTexting",
                "class": "App\\Extensions\\BroadcastProviders\\Sms\\SimpleTextingProvider",
                "auth": null
            },
            "sms_magic": {
                "name": "SMS-Magic",
                "class": "App\\Extensions\\BroadcastProviders\\Sms\\SmsMagicProvider",
                "auth": null
            },
            "sns": {
                "name": "Amazon AWS SNS Message",
                "class": "App\\Extensions\\BroadcastProviders\\Sms\\SnsMessageProvider",
                "auth": null
            },
            "slick_text": {
                "name": "SlickText",
                "class": "App\\Extensions\\BroadcastProviders\\Sms\\SlickTextProvider",
                "auth": null
            },
            "twilio": {
                "name": "Twilio",
                "class": "App\\Extensions\\BroadcastProviders\\Sms\\TwilioProvider",
                "auth": null
            },
            "vonage": {
                "name": "Vonage",
                "class": "App\\Extensions\\BroadcastProviders\\Sms\\VonageProvider",
                "auth": null
            }
        }
    },
    "App_Models_CallToAction": {
        "typeMap": {
            "ai_agent": "SIA AI Assistant",
            "calendar": "Calendar",
            "chat": "Chat",
            "document": "Document",
            "email": "E-mail",
            "form": "Form",
            "iframe": "External Form",
            "message": "Display Message",
            "payments": "Payments",
            "phone": "Phone Number",
            "products": "Products",
            "url": "Website Link",
            "video_reply": "Video Reply",
            "web_page": "Embed Webpage",
            "webinar": "Webinar"
        },
        "phoneSubTypeMap": {
            "call": "Phone Call",
            "sms": "SMS"
        },
        "calendarSubTypeMap": {
            "acuity_scheduler": "Acuity Scheduler",
            "book_me": "Book Like a Boss",
            "calendly": "Calendly",
            "chili_piper": "Chili Piper",
            "hubspot": "HubSpot",
            "groove_app": "Groove",
            "schedule_once": "ScheduleOnce",
            "outlook": "Outlook 365",
            "other": "Other"
        },
        "chatSubTypeMap": {
            "messenger": "Messenger",
            "skype": "Skype",
            "whats_app": "WhatsApp"
        },
        "paymentSubTypeMap": {
            "paypal": "Paypal"
        },
        "webinarSubTypeMap": {
            "big_marker": "BigMarker",
            "easy_webinar": "EasyWebinar",
            "zoom": "Zoom"
        },
        "completeActionMap": {
            "message": "Confirmation Message",
            "url": "Redirect to URL"
        }
    },
    "App_Models_Campaign": {
        "statusMap": {
            "draft": "Draft",
            "failed": "Failed",
            "finished": "Finished",
            "pending": "Pending",
            "sending": "Sending",
            "canceled": "Canceled"
        },
        "typesMap": {
            "email": "Email",
            "sms": "SMS"
        }
    },
    "App_Models_CampaignContact": {
        "statusMap": {
            "exceeds_limit": "Exceeds limit",
            "failed": "Failed",
            "pending": "Pending",
            "success": "Success",
            "unsubscribed": "Unsubscribed",
            "canceled": "Canceled"
        }
    },
    "App_Models_Contact": {
        "sourceMap": {
            "active_campaign": "Active Campaign",
            "api": "API",
            "aweber": "AWeber",
            "chat": "Chat",
            "lofty": "Lofty",
            "close": "Close",
            "connection": "Connection",
            "csv": "CSV",
            "facebook_page": "Facebook Page",
            "follow_up_boss": "FollowUpBoss",
            "form": "Form",
            "google": "Google Contacts",
            "high_level": "HighLevel",
            "hubspot": "HubSpot",
            "manually": "Manual",
            "outlook": "Outlook",
            "realvolve": "Realvolve",
            "salesforce": "Salesforce",
            "woodpecker": "Woodpecker",
            "zapier": "Zapier"
        }
    },
    "App_Models_ContactNote": {
        "typeMap": {
            "note": "Note",
            "email_log": "Email Log",
            "call_log": "Call Log",
            "sms_log": "SMS Log"
        },
        "callOutcomeMap": {
            "busy": "Busy",
            "connected": "Connected",
            "left_live_message": "Left Live Message",
            "left_voicemail": "Left Voicemail",
            "no_answer": "No Answer",
            "wrong_number": "Wrong Number"
        }
    },
    "App_Models_ContactWorkflow": {
        "statusMap": {
            "finished": "Finished",
            "running": "Running",
            "stopped": "Stopped"
        }
    },
    "App_Models_CrmCustomProperty": {
        "formControlTypesMap": {
            "single_line_text": "Single Line Text",
            "multi_line_text": "Multi-line Text",
            "switch": "Switch",
            "checkboxes": "Checkboxes",
            "consent_checkbox": "Consent Checkbox",
            "dropdown": "Dropdown",
            "date": "Date"
        }
    },
    "App_Models_CrmStage": {
        "typesMap": {
            "contact": "Contact",
            "deal": "Deal"
        }
    },
    "App_Models_DubbObject": {
        "integrationCategoryMap": {
            "ad-platforms": "Ad platforms",
            "collaboration": "Collaboration",
            "crm": "CRM",
            "email-marketing": "Email marketing",
            "marketing-automation": "Marketing automation",
            "project-management": "Project management",
            "sales-enablement": "Sales enablement"
        }
    },
    "App_Models_EmailTemplate": [],
    "App_Models_Form": {
        "completeActionsMap": {
            "forward_url": "Forward to URL",
            "message": "Display custom message"
        }
    },
    "App_Models_FormField": {
        "fieldNameLabelsMap": {
            "company_name": "Company",
            "email": "Email",
            "first_name": "First Name",
            "last_name": "Last Name",
            "mobile": "Mobile Phone",
            "phone": "Phone",
            "title": "Title"
        }
    },
    "App_Models_Integration": {
        "typeMap": {
            "active_campaign": "ActiveCampaign",
            "aweber": "AWeber",
            "close": "Close",
            "facebook_page": "Facebook",
            "follow_up_boss": "FollowUpBoss",
            "google_contact": "Google Contact",
            "high_level": "HighLevel",
            "hubspot": "HubSpot",
            "infusionsoft": "Infusionsoft",
            "lofty": "Lofty",
            "ms_contact": "Microsoft Contact",
            "ontraport": "Ontraport",
            "pipedrive": "Pipedrive",
            "realvolve": "Realvolve",
            "salesforce": "Salesforce",
            "salesloft": "SalesLoft",
            "slack": "Slack",
            "sierra_interactive": "Sierra Interactive",
            "sugar_crm": "SugarCRM",
            "wiseagent": "WiseAgent",
            "woodpecker": "Woodpecker"
        }
    },
    "App_Models_KioskUser": {
        "permissionsMap": {
            "admin": "Admin",
            "announcements": "Announcements",
            "email_templates": "Email Templates",
            "landing_page_templates": "Landing Page Templates",
            "leads": "Leads",
            "pages": "Pages & Redirections",
            "settings": "Settings",
            "subscribers": "Subscribers",
            "users": "Users",
            "video_templates": "Video Templates"
        }
    },
    "App_Models_OAuthProfile": [],
    "App_Models_PartnerPromoCode": {
        "partnerMap": {
            "AppSumo": "AppSumo"
        }
    },
    "App_Models_SavedFilter": {
        "typesMap": {
            "contact": "Contact",
            "task": "Task"
        }
    },
    "App_Models_Setting": [],
    "App_Models_Statistics": [],
    "App_Models_Tag": {
        "typeMap": {
            "contact": "Contact",
            "script": "Script",
            "video": "Video"
        },
        "permissionMap": {
            "contact": {
                "edit": "contact_tag:edit",
                "delete": "contact_tag:delete"
            },
            "script": {
                "edit": "script_tag:edit",
                "delete": "script_tag:delete"
            },
            "video": {
                "edit": "video_tag:edit",
                "delete": "video_tag:delete"
            }
        }
    },
    "App_Models_Task": {
        "statusMap": {
            "pending": "Pending",
            "finished": "Completed"
        },
        "recurringPeriodsMap": {
            "daily": "Daily",
            "weekly": "Weekly",
            "monthly": "Monthly",
            "yearly": "Yearly",
            "periodically": "Periodically"
        }
    },
    "App_Models_TeamAsset": {
        "categoriesMap": {
            "avatar_video": "Avatar video source image",
            "cta_document": "Call to action document",
            "virtual_background": "Virtual background image",
            "video_template": "Video template image",
            "email_template": "Email template image",
            "landing_page": "Landing page image"
        },
        "categoriesTypeMap": {
            "cta_document": "doc",
            "virtual_background": "image",
            "video_template": "image",
            "email_template": "image",
            "landing_page": "image",
            "avatar_video": "image"
        },
        "extensionAllowListMap": {
            "image": [
                "jpeg",
                "png",
                "jpg",
                "gif"
            ],
            "doc": [
                "pdf",
                "doc",
                "docx",
                "ppt",
                "pptx",
                "xls",
                "xlsx"
            ]
        }
    },
    "App_Models_TeamDomain": {
        "typeMap": {
            "landing_page": "Landing page",
            "video": "Video \/ Showcase"
        }
    },
    "App_Models_TeamConfigurationSet": {
        "videoPageMap": {
            "video_page_display_business_phone": "Display Business Phone",
            "video_page_display_company_name": "Display Company Name",
            "video_page_display_email_address": "Display Email Address",
            "video_page_display_mobile_phone": "Display Mobile Phone",
            "video_page_display_name": "Display Name",
            "video_page_display_profile_image": "Display Profile Image",
            "video_page_display_profile_title": "Display Profile Title",
            "video_page_display_profile_summary": "Display Profile Summary",
            "video_page_display_social_links": "Display Social Links",
            "video_page_display_web_address": "Display Web Address"
        },
        "brandingMap": {
            "branding_disable_copy_for_email": "Disable Dubb branding and partner revenue on Copy for Email option.",
            "branding_disable_email_campaign": "Disable Dubb branding and partner revenue on email campaigns.",
            "branding_disable_video_embed": "Disable Dubb branding and partner revenue on video embed.",
            "branding_disable_video_widget": "Disable Dubb branding and partner revenue on video widget.",
            "branding_disable_showcase_page": "Disable Dubb branding and partner revenue on showcase pages."
        },
        "fieldMap": {
            "display_business_phone": "video_page_display_business_phone",
            "display_company_name": "video_page_display_company_name",
            "display_email_address": "video_page_display_email_address",
            "display_mobile_phone": "video_page_display_mobile_phone",
            "display_name": "video_page_display_name",
            "display_profile_image": "video_page_display_profile_image",
            "display_profile_title": "video_page_display_profile_title",
            "display_profile_summary": "video_page_display_profile_summary",
            "display_social_link": "video_page_display_social_links",
            "display_web_address": "video_page_display_web_address",
            "facebook_url": "social_url_facebook",
            "instagram_url": "social_url_instagram",
            "linkedin_url": "social_url_linkedin",
            "tiktok_url": "social_url_tiktok",
            "twitter_url": "social_url_twitter",
            "youtube_url": "social_url_youtube",
            "disable_copy_for_email_branding": "branding_disable_copy_for_email",
            "disable_email_campaign_branding": "branding_disable_email_campaign",
            "disable_video_embed_branding": "branding_disable_video_embed",
            "disable_video_widget_branding": "branding_disable_video_widget",
            "disable_showcase_page_branding": "branding_disable_showcase_page"
        }
    },
    "App_Models_TeamMonthlyUsage": {
        "typesMap": {
            "ai_agent": "SIA AI Assistant",
            "ai_completion": "AI Completion",
            "ai_image": "AI Image",
            "avatar_video": "Avatar Video",
            "asset_traffic": "Asset Traffic",
            "asset_view": "Asset View",
            "dubb_sender": "Dubb Campaign Sender",
            "transcribe": "Transcribe",
            "workflow": "Automation"
        }
    },
    "App_Models_TeamRole": {
        "permissionsMap": {
            "CRM": {
                "deal": "Manage deals",
                "deal:view_all": "View all deals",
                "task": "Manage tasks"
            },
            "CAMPAIGNS AND AUTOMATION": {
                "campaign": "Manage campaigns",
                "campaign:view_all": "View all campaigns",
                "workflow": "Manage workflows",
                "email_template": "Manage email templates"
            },
            "CAMPAIGN SETTING": {
                "broadcast_setting:view_all": "View all campaign settings"
            },
            "TEAM": {
                "team:member": "Manage team users",
                "team:role": "Manage team roles",
                "team:configuration_set": "Manage team configuration sets",
                "team:profile": "Manage team profile",
                "team:billing": "Manage team billing",
                "announcement": "Manage team announcements",
                "asset:view_all": "Manage team assets",
                "affiliate": "Affiliate program",
                "integration": "Integrations",
                "group": "Manage groups"
            },
            "CONTACTS": {
                "contact:edit": "Create\/edit contacts",
                "contact:delete": "Delete contacts",
                "contact:export": "Export contacts",
                "contact:import": "Import contacts from CSV",
                "contact:sync": "Sync contacts from Gmail\/Outlook",
                "contact:view_all": "View all contacts",
                "contact_tag:edit": "Create\/edit tags",
                "contact_tag:delete": "Delete tags"
            },
            "CALL TO ACTION": {
                "cta:edit": "Create\/edit CTAs",
                "cta:delete": "Delete CTAs",
                "cta:view_all": "View all CTAs",
                "form": "Manage forms",
                "messaging": "Dubb messaging"
            },
            "VIDEOS": {
                "video:edit": "Create\/edit videos",
                "video:delete": "Delete videos",
                "video:template": "Manage video templates",
                "video:template:view_all": "View all video templates",
                "video:view-private": "View non-shared videos",
                "video:view-group-private": "View non-shared videos inside group",
                "video_tag:edit": "Create\/edit tags",
                "video_tag:delete": "Delete tags",
                "video:manage_privacy": "Manage video privacy"
            },
            "SHOWCASES": {
                "showcase:view_all": "View all showcases",
                "showcase:edit": "Create\/edit showcases",
                "showcase:delete": "Delete showcases"
            },
            "PRESETS": {
                "preset:view_all": "View all presets",
                "preset:delete": "Create\/edit presets",
                "preset:edit": "Delete presets"
            },
            "SCRIPTS": {
                "script:view_all": "View all scripts",
                "script:edit": "Create\/edit scripts",
                "script:delete": "Delete scripts",
                "script_tag:edit": "Create\/edit tags",
                "script_tag:delete": "Delete tags"
            }
        }
    },
    "App_Models_TeamSetting": [],
    "App_Models_Track": {
        "actionMap": {
            "click_cta": "CTA Clicks",
            "click_email": "Email Clicks",
            "emoji": "Emoji Reactions",
            "form": "Form Submissions",
            "open_email": "Email Opens",
            "video_reply": "Video Replies",
            "view": "Page Views",
            "watch": "Video Views"
        },
        "trackSumMap": {
            "click_cta": "cta_clicks_count",
            "click_email": "click_emails_count",
            "emoji": "emoji_count",
            "form": "form_count",
            "open_email": "open_emails_count",
            "video_reply": "video_reply_count",
            "view": "views_count",
            "watch": "video_views_count"
        }
    },
    "App_Models_UserDelegation": {
        "statusMap": {
            "accepted": "Accepted",
            "ignored": "Ignored",
            "pending": "Pending"
        }
    },
    "App_Models_UserProfile": {
        "genderMap": {
            "female": "Female",
            "male": "Male"
        }
    },
    "App_Models_UserSetting": {
        "notificationMap": {
            "click_cta": "CTA Clicks",
            "click_email": "Email Clicks",
            "emoji": "Emoji Reactions",
            "form": "Form Submissions",
            "open_email": "Email Opens",
            "video_reply": "Video Replies",
            "view": "Page Views",
            "watch": "Video Views",
            "notification_chat": "Dubb Messaging"
        }
    },
    "App_Models_Video": {
        "publishStatusMap": {
            "searchable": "Feature",
            "url_only": "Shareable",
            "private": "Draft"
        },
        "publishStatusDescMap": {
            "searchable": "Feature: Publicly visible on your profile page",
            "url_only": "Shareable: Send via link, email or social",
            "private": "Draft: Only visible in my account"
        },
        "sourceChannelMap": {
            "android": "Android",
            "chrome_extension": "Chrome Extension",
            "ios": "iOS",
            "mac_os": "macOS",
            "website": "Website",
            "windows": "Windows"
        },
        "sourceTypeMap": {
            "record": "Record",
            "upload": "Upload"
        }
    },
    "App_Models_VideoAnalysis": [],
    "App_Models_WorkflowNode": {
        "actionMap": {
            "add_tag": "Add Tag",
            "condition": "If\/Then Branch",
            "create_deal": "Create Deal",
            "create_task": "Create Task",
            "delay": "Delay",
            "notify": "Send Internal Notification",
            "remove_tag": "Remove Tag",
            "send_campaign": "Send Email\/SMS",
            "update_stage": "Update Stage"
        },
        "delayUnitMap": {
            "minutes": "Minutes",
            "hours": "Hours",
            "days": "Days",
            "weeks": "Weeks",
            "months": "Months"
        },
        "delayTypeMap": {
            "fixed": "Delay until a time amount",
            "day_time": "Delay until a day and time"
        },
        "conditionCheckTypeMap": {
            "instant": "Instant",
            "delayed": "Delayed"
        },
        "notifyViaMap": {
            "email": "Email"
        }
    },
    "App_Models_Objects_AiProfileSettings": {
        "settingMap": {
            "name": "Your name is",
            "company": "Your company is",
            "profession": "Your profession is",
            "audience": "Your target audience is",
            "selling_proposition": "Your unique selling proposition is",
            "company_offers": "Your company offers",
            "definition": "Your definition of success for your clients is",
            "action": "Your typical call-to-action in your sales messaging is",
            "tones": "Your preferred tone of communication is",
            "emoji": "Include emojis in your copy",
            "action_notes": "Include action notes",
            "charm": "Use CHARM method"
        }
    },
    "App_Models_Objects_CrmObjectType": {
        "objectTypesMap": {
            "company": "Company",
            "contact": "Contact",
            "deal": "Deal"
        }
    },
    "App_Models_Objects_FormExtra": {
        "forwardTypesMap": {
            "same_tab": "Same Tab",
            "new_window": "New Window"
        }
    },
    "App_Models_Objects_TaskRecurringOptions": [],
    "App_Models_Objects_WorkflowData": [],
    "App_Models_Objects_WorkflowCondition": {
        "triggerMap": {
            "contact_stage_changed": "Contact stage changed",
            "cta_clicked": "CTA clicked",
            "email_opened": "Email opened",
            "deal_stage_changed": "Deal stage changed",
            "tag_added": "Tag added",
            "video_page_viewed": "Video page viewed",
            "video_replied": "Video replied",
            "video_watched": "Video watched"
        },
        "conditionMap": {
            "contact_stage_changed": "Contact stage changed",
            "cta_clicked": "CTA clicked",
            "deal_stage_changed": "Deal stage changed",
            "email_opened": "Email opened",
            "tag_added": "Tag exists",
            "video_page_viewed": "Video page viewed",
            "video_replied": "Video replied",
            "video_watched": "Video watched"
        },
        "operatorMap": {
            "all_of": "includes all of",
            "any": "is any",
            "any_of": "includes any of",
            "none_of": "includes none of"
        },
        "typeOperatorMap": {
            "contact_stage_changed": [
                "any_of"
            ],
            "cta_clicked": [
                "any_of"
            ],
            "deal_stage_changed": [
                "any_of"
            ],
            "email_opened": [
                "any_of",
                "all_of"
            ],
            "tag_added": [
                "any_of",
                "all_of"
            ],
            "video_page_viewed": [
                "any_of",
                "all_of"
            ],
            "video_replied": [
                "any_of",
                "all_of"
            ],
            "video_watched": [
                "any_of",
                "all_of"
            ]
        }
    },
    "App_Models_Objects_WorkflowCondition_VideoExtra": {
        "operatorsMap": {
            "=": "=",
            ">": ">",
            ">=": ">=",
            "<": "<",
            "<=": "<="
        }
    },
    "App_Extensions_OpenAi_Enums_Voice": [],
    "App_User": [],
    "App_Extensions_BroadcastProviders_Email_GmailApiEmailProvider": {
        "fieldMap": []
    },
    "App_Extensions_BroadcastProviders_Email_GmailSmtpProvider": {
        "fieldMap": {
            "from_address": {
                "label": "Gmail Username"
            },
            "password": {
                "label": "Gmail Password",
                "type": "password"
            }
        }
    },
    "App_Extensions_BroadcastProviders_Email_MailgunProvider": {
        "fieldMap": {
            "from_address": {
                "label": "From Email"
            },
            "domain": {
                "label": "Domain"
            },
            "key": {
                "label": "API Key",
                "type": "password"
            },
            "region": {
                "label": "Region",
                "type": "dropdown",
                "required": false,
                "options": {
                    "": "Global",
                    "eu": "Europe"
                }
            }
        }
    },
    "App_Extensions_BroadcastProviders_Email_MandrillSmtpProvider": {
        "fieldMap": {
            "from_address": {
                "label": "From Email"
            },
            "username": {
                "label": "Mandrill Username"
            },
            "password": {
                "label": "Mandrill Password",
                "type": "password"
            }
        }
    },
    "App_Extensions_BroadcastProviders_Email_OutlookApiEmailProvider": {
        "fieldMap": []
    },
    "App_Extensions_BroadcastProviders_Email_SendGridSmtpProvider": {
        "fieldMap": {
            "from_address": {
                "label": "From Email"
            },
            "password": {
                "label": "SendGrid Api Key",
                "type": "password"
            }
        }
    },
    "App_Extensions_BroadcastProviders_Email_SesEmailProvider": {
        "fieldMap": {
            "from_address": {
                "label": "From Address"
            },
            "key": {
                "label": "SES Key",
                "type": "password"
            },
            "secret": {
                "label": "SES Secret",
                "type": "password"
            },
            "region": {
                "label": "SES Region",
                "type": "dropdown",
                "options": {
                    "": "Select",
                    "us-east-1": "US East (N. Virginia)(us-east-1)",
                    "us-east-2": "US East (Ohio)(us-east-2)",
                    "us-west-1": "US West (N. California)(us-west-1)",
                    "us-west-2": "US West (Oregon)(us-west-2)",
                    "ap-east-1": "Asia Pacific (Hong Kong)(ap-east-1)",
                    "ap-south-1": "Asia Pacific (Mumbai)(ap-south-1)",
                    "ap-northeast-2": "Asia Pacific (Seoul)(ap-northeast-2)",
                    "ap-southeast-1": "Asia Pacific (Singapore)(ap-southeast-1)",
                    "ap-southeast-2": "Asia Pacific (Sydney)(ap-southeast-2)",
                    "ap-northeast-1": "Asia Pacific (Tokyo)(ap-northeast-1)",
                    "ca-central-1": "Canada (Central)(ca-central-1)",
                    "eu-central-1": "Europe (Frankfurt)(eu-central-1)",
                    "eu-west-1": "Europe (Ireland)(eu-west-1)",
                    "eu-west-2": "Europe (London)(eu-west-2)",
                    "eu-west-3": "Europe (Paris)(eu-west-3)",
                    "eu-north-1": "Europe (Stockholm)(eu-north-1)",
                    "me-south-1": "Middle East (Bahrain)(me-south-1)",
                    "sa-east-1": "South America (S\u00e3o Paulo)(sa-east-1)"
                }
            }
        }
    },
    "App_Extensions_BroadcastProviders_Email_SmtpEmailProvider": {
        "fieldMap": {
            "server": {
                "label": "SMTP Server"
            },
            "port": {
                "label": "SMTP Port"
            },
            "from_address": {
                "label": "SMTP Email Address"
            },
            "username": {
                "label": "SMTP Login Username",
                "required": false,
                "placeholder": "Optional"
            },
            "password": {
                "label": "SMTP Password",
                "type": "password"
            },
            "encryption": {
                "label": "Encryption",
                "required": false,
                "type": "dropdown",
                "options": {
                    "": "None",
                    "ssl": "SSL",
                    "tls": "TLS"
                }
            }
        }
    },
    "App_Extensions_BroadcastProviders_Sms_EightXEightProvider": {
        "fieldMap": {
            "sub_account_id": {
                "label": "Sub Account ID"
            },
            "api_key": {
                "label": "API Key",
                "type": "password"
            },
            "from_address": {
                "label": "Send Number",
                "required": false
            }
        }
    },
    "App_Extensions_BroadcastProviders_Sms_ClickSendProvider": {
        "fieldMap": {
            "username": {
                "label": "UserName"
            },
            "password": {
                "label": "API Key",
                "type": "password"
            },
            "from_address": {
                "label": "Send Number",
                "required": false
            }
        }
    },
    "App_Extensions_BroadcastProviders_Sms_DialpadProvider": {
        "fieldMap": {
            "api_key": {
                "label": "API Key",
                "type": "password"
            },
            "from_address": {
                "label": "Send Number"
            }
        }
    },
    "App_Extensions_BroadcastProviders_Sms_EzTextingProvider": {
        "fieldMap": {
            "username": {
                "label": "UserName"
            },
            "password": {
                "label": "Password",
                "type": "password"
            },
            "from_address": {
                "label": "Send Number",
                "required": false
            }
        }
    },
    "App_Extensions_BroadcastProviders_Sms_JustCallProvider": {
        "fieldMap": {
            "api_key": {
                "label": "API Key"
            },
            "api_secret": {
                "label": "API Secret",
                "type": "password"
            },
            "from_address": {
                "label": "Phone Number",
                "help_text": "JustCall SMS capabled number in E.164 format - ex +14155552671"
            }
        }
    },
    "App_Extensions_BroadcastProviders_Sms_RingCentralProvider": {
        "fieldMap": {
            "from_address": {
                "label": "Phone Number"
            },
            "jwt": {
                "label": "JWT"
            }
        }
    },
    "App_Extensions_BroadcastProviders_Sms_SakariProvider": {
        "fieldMap": {
            "account_id": {
                "label": "Account ID"
            },
            "client_id": {
                "label": "Client ID"
            },
            "client_secret": {
                "label": "Client Secret",
                "type": "password"
            }
        }
    },
    "App_Extensions_BroadcastProviders_Sms_SalesMessageProvider": {
        "fieldMap": []
    },
    "App_Extensions_BroadcastProviders_Sms_SignalWireProvider": {
        "fieldMap": {
            "space": {
                "label": "Space subdomain",
                "help_text": "Space Url without .signalwire.com, e.g. dubb.signalwire.com, then the subdomain is dubb"
            },
            "project_id": {
                "label": "Project ID"
            },
            "api_token": {
                "label": "API Token",
                "type": "password"
            },
            "from_address": {
                "label": "Phone Number",
                "help_text": "e.g. +1 (555) 123-4567 would be formatted like: +15551234567"
            }
        }
    },
    "App_Extensions_BroadcastProviders_Sms_SimpleTextingProvider": {
        "fieldMap": {
            "api_token": {
                "label": "API Token",
                "type": "password"
            },
            "from_address": {
                "label": "Phone Number",
                "required": false
            }
        }
    },
    "App_Extensions_BroadcastProviders_Sms_SmsMagicProvider": {
        "fieldMap": {
            "from_address": {
                "label": "Send Number"
            },
            "api_key": {
                "label": "API Key",
                "type": "password"
            }
        }
    },
    "App_Extensions_BroadcastProviders_Sms_SnsMessageProvider": {
        "fieldMap": {
            "key": {
                "label": "AWS Key",
                "type": "password"
            },
            "secret": {
                "label": "AWS Secret",
                "type": "password"
            },
            "region": {
                "label": "SNS Region",
                "type": "dropdown",
                "options": {
                    "": "Select",
                    "us-east-1": "US East (N. Virginia)(us-east-1)",
                    "us-west-2": "US West (Oregon)(us-west-2)",
                    "ap-south-1": "Asia Pacific (Mumbai)(ap-south-1)",
                    "ap-southeast-1": "Asia Pacific (Singapore)(ap-southeast-1)",
                    "ap-southeast-2": "Asia Pacific (Sydney)(ap-southeast-2)",
                    "ap-northeast-1": "Asia Pacific (Tokyo)(ap-northeast-1)",
                    "eu-central-1": "Europe (Frankfurt)(eu-central-1)",
                    "eu-west-1": "Europe (Ireland)(eu-west-1)"
                }
            },
            "from_address": {
                "label": "Sender ID",
                "required": false
            },
            "sms_type": {
                "label": "SMS Type",
                "type": "dropdown",
                "options": {
                    "Promotional": "Promotional",
                    "Transactional": "Transactional"
                }
            }
        }
    },
    "App_Extensions_BroadcastProviders_Sms_SlickTextProvider": {
        "fieldMap": {
            "pub_key": {
                "label": "Public Key"
            },
            "pri_key": {
                "label": "Private Key",
                "type": "password"
            }
        }
    },
    "App_Extensions_BroadcastProviders_Sms_TwilioProvider": {
        "fieldMap": {
            "sid": {
                "label": "SID"
            },
            "token": {
                "label": "Token",
                "type": "password"
            },
            "from_address": {
                "label": "Send Number",
                "required": false
            },
            "max_price": {
                "label": "Max Price",
                "required": false
            },
            "forward_reply_to": {
                "label": "Forward Reply",
                "required": false,
                "help_text": "When someone replies to a twilio sms, the reply is sent to the designated user via email",
                "help_link": "https:\/\/support.dubb.com\/en\/articles\/5796018-how-to-configure-twilio-replies,php"
            }
        }
    },
    "App_Extensions_BroadcastProviders_Sms_VonageProvider": {
        "fieldMap": {
            "api_key": {
                "label": "API Key"
            },
            "api_secret": {
                "label": "API Secret",
                "type": "password"
            },
            "from_address": {
                "label": "Send Number"
            }
        }
    }
};